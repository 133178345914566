import React from 'react';
import BylineList from 'components/Byline';
import ArticleHeroEyebrow from 'components/ArticleHeroEyebrow';
import useDateTime from 'hooks/useDateTime';
import { Article } from 'interfaces/content/articles/Articles';
import FeaturedHeroMedia from 'components/FeaturedHeroMedia';
import SocialShareDropdown from 'components/SocialShareDropdown';
import { Microsites } from 'constants/constants';
import getArticlePageTestId from 'constants/testsIds/articlePage';
import * as S from './heroStyles';

export interface HeroProps {
  article: Article;
  microsite?: string;
}

const Hero: React.FC<HeroProps> = ({ article, microsite }) => {
  const {
    authors,
    dateGmt,
    modifiedGmt,
    showModifiedTimestamp,
    featuredMediaType,
    imageCaption,
    imageCredit,
    postSettings,
    videoId,
    title,
  } = article;

  const localDate = useDateTime(dateGmt);
  const updatedDate = useDateTime(modifiedGmt);
  const isUpdated = !(localDate === updatedDate);

  const primarySection = {
    link: article.primarySection.uri,
    name: article.primarySection.name,
  };

  const primaryPostTag = {
    link: article.primaryPostTag.uri,
    name: article.primaryPostTag.name,
  };

  const trackingData = {
    contentPlacementCD: 'article side by side hero',
    eventAction: 'hero click',
    eventCategory: 'navigation',
  };

  const socialShareTrackingData = {
    contentPlacementCD: 'article side by side hero',
    eventAction: 'Social Share -',
    eventCategory: 'Social',
  };

  const hasImageOrVideo = featuredMediaType || videoId;

  return (
    <S.HeroAndImageWrapper>
      <S.RedesignedHeroWrapper data-cy={getArticlePageTestId('EYEBROW')}>
        <ArticleHeroEyebrow
          primarySection={primarySection}
          primaryPostTag={primaryPostTag}
          className='eyebrow'
          trackingData={trackingData}
        />
        <S.RedesignedHeroTitle
          data-cy={getArticlePageTestId('TITLE')}
          as='h1'
          $fontFamily='saol'
          $size={{ default: 'level3', mdMin: 'large' }}
        >
          {title}
        </S.RedesignedHeroTitle>
        <S.BylineAndShareWrapper>
          <S.RedesignedHeroBylineWrapper data-cy={getArticlePageTestId('AUTHOR_AND_DATE')}>
            <S.RedesignedHeroAuthorsContainer>
              <BylineList
                data-cy={getArticlePageTestId('AUTHORS')}
                bylineList={authors}
                isArticle
                description={article.postSettings?.bylineCommentary}
                className='article-info'
                trackingData={{ ...trackingData, eventLabel: 'author click' }}
              />
            </S.RedesignedHeroAuthorsContainer>
            {!(Microsites.Recommends === microsite && showModifiedTimestamp && isUpdated) ? (
              <S.RedesignedHeroDate>{localDate}</S.RedesignedHeroDate>
            ) : null}

            {showModifiedTimestamp && isUpdated ? (
              <S.RedesignedHeroDate>Updated {updatedDate}</S.RedesignedHeroDate>
            ) : null}
          </S.RedesignedHeroBylineWrapper>
          <SocialShareDropdown
            title={article.title}
            isArticleRedesign
            trackingData={socialShareTrackingData}
          />
        </S.BylineAndShareWrapper>
      </S.RedesignedHeroWrapper>
      {hasImageOrVideo && (
        <S.RedesignedHeroImageContainer data-cy={getArticlePageTestId('IMAGE')}>
          <FeaturedHeroMedia
            className='article-video'
            featuredMediaType={featuredMediaType}
            imageCaption={imageCaption}
            imageCredit={imageCredit}
            postSettings={postSettings}
            videoId={videoId}
          />
        </S.RedesignedHeroImageContainer>
      )}
    </S.HeroAndImageWrapper>
  );
};

export { Hero };
