import { css } from 'styled-components';
import { Theme, getColor } from 'styles/theme';

const links = css<{ theme: Theme }>`
  a {
    color: ${getColor('textLink')};
    text-decoration: none;
    font-family: inherit;

    &:hover {
      text-decoration: none;
    }

    &:active,
    &:focus {
      text-decoration: none;
      color: ${getColor('textSecondary')};
    }
  }
`;

export default links;
