import RawHtml from 'utils/miscUtils/rawHtml';
import SubscriptionPleaContainer from './SubscriptionPleaStyles';

export interface SubscriptionPleaProps {
  enableSubscriptionPlea: boolean;
  pageType: string;
  subscriptionPleaTextLined: string;
}

const SubscriptionPlea = (props: SubscriptionPleaProps) => {
  const { enableSubscriptionPlea, pageType, subscriptionPleaTextLined } = props;

  if (!subscriptionPleaTextLined) return null;

  return (
    <SubscriptionPleaContainer
      className={pageType}
      $hasContent={subscriptionPleaTextLined}
      $showSubscriptionPlea={enableSubscriptionPlea}
      data-cy='subscriptionPlea'
    >
      {RawHtml({ html: subscriptionPleaTextLined })}
    </SubscriptionPleaContainer>
  );
};

export default SubscriptionPlea;
