import styled from 'styled-components';
import { blockQuotes, pullQuotes, longQuotes } from 'styles/quotes';
import { headers } from 'styles/headers';
import { images } from 'styles/images';
import { stories } from 'styles/stories';
import { breakpoint, color, font } from 'styles/globals';
import { paragraphs, codeBlocks } from 'styles/paragraphs';
import { tables } from 'styles/tables';
import { videos } from 'styles/videos';
import links from 'styles/links';
import { Microsites } from 'constants/constants';
import { Theme, getBodyStyle } from 'styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 1344px;
  margin: 0 auto;
`;

export const ArticleContentContainer = styled.div`
  padding: 0 16px;

  figure[class*="wp-block-embed-youtube"] {
    div.wp-block-embed__wrapper {
      aspect-ratio: 16 / 9;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (${breakpoint.mdMax}) {
    div.wp-block-embed__wrapper {
      iframe {
        width: 100%;
      }
    }
  }

  .source-caption {
    border: solid red;
  }
`;

export const ArticleImageAndContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  .twitter-tweet {
    margin: auto !important;
  }

  iframe.instagram-media {
    margin: auto !important;
  }

  .wp-block-embed__wrapper {
    max-width: none;

    @media (${breakpoint.mdMin}) {
      margin: 0 auto 24px auto;
      padding: 0;
      width: calc((((100% - 264px) / 12) * 10) + 216px) !important;
    }
    @media (${breakpoint.xlMin}) {
      width: calc((((100% - 336px) / 8) * 6) + 336px) !important;
    }
    @media (${breakpoint.xxlMin}) {
      width: calc((((100% - 288px) / 7) * 5) + 288px) !important;
    }
  }

  figure {
    .wp-block-embed__wrapper {
      margin: 0;

      @media (${breakpoint.mdMin}) {
        width: 100% !important;
      }
    }
  }
`;

interface Subdomain {
  $subdomain?: string;
  $stickyTop?: number | null;
  $color?: string;
  theme: Theme;
}

const handleScrollJump = (subdomain?: string) => {
  switch (subdomain) {
    case null:
      return '195px';
    case Microsites.Well:
      return '80px';
    case Microsites.Crypto:
      return '130px';
    case Microsites.Recommends:
      return '80px';
    default:
      break;
  }
  return null;
};

const handleScrollJumpMobile = (subdomain?: string) => {
  switch (subdomain) {
    case null:
      return '115px';
    case Microsites.Well:
      return '55px';
    case Microsites.Crypto:
      return '110px';
    case Microsites.Recommends:
      return '55px';
    default:
      break;
  }
  return null;
};

export const ArticleContentWrapper = styled.div<Subdomain & { theme: Theme }>`
  ${paragraphs};
  ${headers};
  ${images};
  ${blockQuotes};
  ${pullQuotes};
  ${longQuotes};
  ${stories};
  ${videos};
  ${codeBlocks};
  ${tables};
  ${links}
  
  ${getBodyStyle('georgia', { default: 'default' })};
  margin: 0 auto;
  width: 100%;

  @media (${breakpoint.mdMin}) {
    max-width: 719px;
  }

  @media (${breakpoint.xlMin}) {
    max-width: 745px;
  }

  p {
    word-break: break-word;
    color: ${color.primaryBlack};
  }

  div[class^="rawHtml-content"] {
    > p:first-of-type {
      margin-top: 0px;
    }
    h1, h2, h3, h4, h5, h6 {
      scroll-margin-top: ${({ $subdomain }) => handleScrollJump($subdomain)};
      @media (${breakpoint.mdMax}) {
        scroll-margin-top: ${({ $subdomain }) => handleScrollJumpMobile($subdomain)};
      }
      color: ${color.primaryBlack};
    }
    iframe {
      aspect-ratio: 16 / 9;
    }

    @media (${breakpoint.mdMin}) {
      .paywall-selector {
        top: ${({ $stickyTop }) => ($stickyTop ? `${$stickyTop + 20}px` : '10%')};
      }

      .tp-container-inner {
        iframe {
          margin-left: -10px;
        }
      }
    }
  }

  .related-video-container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    &.hidden {
      display: none;
    }

    > div {
      width: 100%;
    }
    
    h3 {
      font-weight: 600;
      line-height: 39px;
      font-size: 24px;
      margin: 8px 0 !important; /* All global headers have margin with !important property */
    
      @media (${breakpoint.mdMin}) {
        font-size: 32px;
      }
    }

    hr {
      width: 150px;
      margin: 0 0 24px;
      border: 0;
      border-top: 4px solid ${color.primaryRed};

      @media (${breakpoint.mdMin}) {
        width: 200px;
      }
    }
  }

  .wp-block-button {
    background-color: ${(props) => props.$color};
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    width: fit-content;
    height: fit-content;
    padding: 8px 16px;
    position: relative;

    > a::before {
      content: '';
      position: absolute;
      inset: 0;
    }

    > a {
      color: ${color.primaryWhite};
    }
  }

  .pros_cons div {
    max-width: 100%;
  }

  .table-container{
    overflow: auto;
  }

  ${({ $subdomain }) =>
    Microsites.Recommends === $subdomain &&
    `
    h5.wp-block-heading {
      font-family: ${font.graphikCompact};
      color: ${color.recommendsDarkGreen};
      font-weight: 600;
      font-size: 16px;
      line-height: 19.2px;
    }
  `}
`;

export const BreadcrumbsContainer = styled.div`
  margin: 24px 16px 0;

  @media (${breakpoint.mdMin}) {
    margin: 24px auto 0;
    width: calc(((100% / 12) * 10) - 24px);
  }

  @media (${breakpoint.lgMin}) {
    margin-top: 48px;
  }

  @media (${breakpoint.xlMin}) {
    width: 100%;
  }

  @media (${breakpoint.xxlMin}) {
    margin: 48px 0 0 auto;
    width: calc(((100% / 12) * 10) - 8px);
  }
`;

export const FooterStyles = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const TrinityPlayerContainer = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
    margin: -24px;
    padding: 0 16px;

    @media (${breakpoint.mdMin}) {
      max-width: 704px;
      margin: unset;
      padding: unset;
    }

    @media (${breakpoint.xlMin}) {
      max-width: 745px;
    }
`;
