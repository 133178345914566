import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div`
  &.latest-popular-module {
    display: flex;
    flex-direction: column;
    gap: 40px;
  
    .latest-popular-content {
      display: grid;
      grid-auto-flow: row;
      grid-auto-columns: 1fr;
      gap: 40px;

      @media (${breakpoint.mdMin}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 48px;
      }
    }

    @media (${breakpoint.mdMin}) {
      gap: 48px;
    }
  }
`;

export const PostList = styled.ul``;

export const PostWrapper = styled.li`
  &.content-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const LatestHeading = styled.div<{ theme: Theme }>`
  &.heading {
    display: flex;
    align-items: center;

    &::before, &::after {
      content: '';
      flex-grow: 1;
      height: 0;
      background-color: ${getColor('surfaceSecondary')};
    }

    @media (${breakpoint.mdMin}) {
      &::before, &::after {
        height: 1px;
      }

      &::before {
        margin-right: 56px;
      }
    
      &::after {
        margin-left: 56px;
      }
    }

    @media (${breakpoint.xxlMin}) {
      &::before {
        margin-right: 104px;
      }
    
      &::after {
        margin-left: 104px;
      }
    }
  }
`;

export const Title = styled(Heading)`
  &.latest-popular-title {
    letter-spacing: -1.5px;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: ${getColor('accentSecondary')};
    text-decoration-thickness: 4px;
  }
`;

export const TimeAgo = styled.span<{ theme: Theme }>`
  &.time-ago-wrapper{
    display: flex;
    justify-content: center;
    position: relative;
    top: 16px;
    margin-left: auto;
    height: 32px;
    z-index: 100;
    ${getBodyStyle('graphikCond', { default: 'small' }, 'semiBold')};
    background-color: ${getColor('neutralSoftBlack')};
    color: ${getColor('textWhite')};
    line-height: 120%;
    letter-spacing: 2px;
    padding: 8px 16px;
  
    :before{
      content: url("/icons/TimerClockWhite.svg"); 
      padding-right: 6px;
      padding-top: 2px;
    }
  }
`;
