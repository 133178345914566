import { useState } from 'react';
import SocialLinks from 'components/SocialLinks';
import { ShareIcon } from 'icons/Share';
import useBreakpoint from 'hooks/useBreakpoint';
import { EventData } from 'services/Gtm/entities';
import { Wrapper, DropdownContainer, Dropdown, DropdownContent } from './SocialShareDropdownStyles';

interface Props {
  title: string;
  isArticleRedesign?: boolean;
  trackingData?: EventData;
}

const SocialShareDropdown = ({ title, isArticleRedesign = false, trackingData }: Props) => {
  const [showContent, setShowContent] = useState(false);
  const isMobile = useBreakpoint('mdMax');

  const onClickHandler = () => setShowContent((sC) => !sC);

  return (
    <Wrapper>
      <DropdownContainer className={`${isArticleRedesign ? 'article-redesign' : ''}`}>
        <div
          role='button'
          tabIndex={0}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          aria-label='share-button'
        >
          <ShareIcon className='share-icon' />
          {!isArticleRedesign ? <p className='title'>Share</p> : null}
        </div>
        <Dropdown className={isMobile && showContent ? 'show' : `${isArticleRedesign ? 'article-redesign' : ''}`}>
          <DropdownContent>
            <SocialLinks
              title={title}
              className='social-links-container'
              trackingData={trackingData}
            />
          </DropdownContent>
        </Dropdown>
      </DropdownContainer>
    </Wrapper>
  );
};

export default SocialShareDropdown;
