import { useEffect, useState } from 'react';
import useIsBrowser from 'hooks/useIsBrowser';

interface Coordinates {
  top: number | null;
  left: number | null;
  right: number | null;
  bottom: number | null;
}

const getAbsoluteCoordinates = (boundingRect: DOMRect | null): Coordinates => {
  if (! boundingRect) return { bottom: null, left: null, right: null, top: null };
  return {
    bottom: boundingRect.bottom,
    left: boundingRect.left,
    right: boundingRect.right,
    top: boundingRect.top,
  };
};

export default function useHeaderCoordinates(): Coordinates {
  const [headerCoordinates, setHeaderCoordinates] = useState<Coordinates>({
    bottom: null,
    left: null,
    right: null,
    top: null,
  });
  const isBrowser = useIsBrowser();

  useEffect(() => {
    if (isBrowser) {
      const boundingRect = document.getElementById('header-wrapper-id')!.getBoundingClientRect();
      setHeaderCoordinates(getAbsoluteCoordinates(boundingRect));
    }
  }, [isBrowser]);

  useEffect(() => {
    const coordinatesUpdate = () => {
      const boundingRect = document.getElementById('header-wrapper-id')!.getBoundingClientRect();
      setHeaderCoordinates((previousCoordinates) => {
        if (
          previousCoordinates.top !== boundingRect.top ||
          previousCoordinates.bottom !== boundingRect.bottom ||
          previousCoordinates.left !== boundingRect.left ||
          previousCoordinates.right !== boundingRect.right
        ) {
          return getAbsoluteCoordinates(boundingRect);
        }
        return previousCoordinates;
      });
    };

    document.addEventListener('scroll', coordinatesUpdate);
    document.addEventListener('resize', coordinatesUpdate);

    return () => {
      document.removeEventListener('scroll', coordinatesUpdate);
      document.removeEventListener('resize', coordinatesUpdate);
    };
  }, []);

  return headerCoordinates;
}
