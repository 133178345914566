import { useEffect } from 'react';

export interface Props {
  url: string;
  noTrinityAds?: boolean;
}

const TrinityPlayer = ({ url, noTrinityAds }: Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://trinitymedia.ai/player/trinity/2900017491/?pageURL=${url}&FAB=fabAfterClick&subscriber=${
      noTrinityAds ? 1 : 0
    }`;
    script.async = true;
    script.type = 'text/javascript';

    const targetDiv = document.querySelector('.trinity-player');

    if (targetDiv) {
      targetDiv.appendChild(script);
    }

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [url, noTrinityAds]);

  return (
    <div
      className='trinity-tts-pb'
      dir='ltr'
    >
      <strong style={{ fontWeight: '400' }}>
        Getting your{' '}
        <a
          href='//trinityaudio.ai'
          style={{ color: '#4b4a4a', fontWeight: '700', textDecoration: 'none' }}
        >
          Trinity Audio
        </a>{' '}
        player ready...
      </strong>
    </div>
  );
};

export default TrinityPlayer;
